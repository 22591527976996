import React from 'react';
import './AboutUs.css';
import profpic1 from '../assets/images/michael-pic.png'; // Placeholder for image 1
import profpic2 from '../assets/images/gabriel-pic.png'; // Placeholder for image 2
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'; // Import LinkedIn icon

const AboutUs = () => {
  return (
    <section className="about-us" id="about-us">
      <h2>About Us</h2>

      {/* Text below the heading and above the team members */}
      <div className="about-description">
        <p>
          Gabriel and Michael became friends when they were working at Silicon Valley.
        </p>
        <p>
          Michael worked as Operations and Analytics professional with experience in leading
        </p>
        <p>
          complex analytics & ML projects in the Space and Tech sectors in Silicon Valley.
        </p>
        <p>
          Gabriel worked as a Software Engineering, Co-Founder, Scientific Researcher 
        </p>
        <p>
          in Web, AI/ML, Robotics, and Biotech for Startups across Silicon Valley.
        </p>
        <p>
          They believe AI labeling, fine tuning, and reinforcement learning should
        </p>
        <p>
          should be fast, cheap, automated, and scaled with synthetic data.
        </p>          
      </div>

      <br></br>
      <br></br>

      <div className="about-us-container">
        <div className="team-member">
          <img src={profpic2} alt="Gabriel Baki" />
          <h3>Gabriel Baki</h3>
          <p>Co-Founder 
            <a href="https://www.linkedin.com/in/gabriel-baki-725560102/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} className="linkedin-icon" />
            </a>
          </p>
        </div>
        <div className="team-member">
          <img src={profpic1} alt="Michael Wicaksono" />
          <h3>Michael Wicaksono</h3>
          <p>Co-Founder 
            <a href="https://www.linkedin.com/in/mwicak/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} className="linkedin-icon" />
            </a>
          </p>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
