import React from 'react';
import './GetStarted.css';
import processImage1 from '../assets/images/process-1.png'; // Placeholder for image 1
import processImage2 from '../assets/images/process-2.png'; // Placeholder for image 2

const GetStarted = () => {
  return (
    <section className="get-started" id="get-started">
      <h2>Get started in just minutes!</h2>
      
      {/* Steps section */}
      <div className="steps">
        <div className="step">
            <h3>01</h3>
            <h4>Provide Sample Dataset</h4>
            <p>Upload 1-10% Sample Labelled Data & Unlabelled Data. Our platform quickly analyzes and prepares your data for efficient model training.</p>
        </div>
        <div className="step">
            <h3>02</h3>
            <h4>MultiModal Fine Tuning</h4>
            <p>We Fine Tune your model across multiple data types, completely automating and scaling your training process.</p>
        </div>
        <div className="step">
            <h3>03</h3>
            <h4>10x Synthetic Golden Data</h4>
            <p>Generate a multiplied, high-quality labelled dataset with precision, ready for seemless integration into your AI models.</p>
        </div>
        <div className="step">
            <h3>04</h3>
            <h4>Performance Evaluation</h4>
            <p>Evaluate the model’s performance with our built-in benchmarking tools, ensuring alignment and continual improvements.</p>
        </div>
      </div>
      
      {/* Screenshots section */}
      <div className="get-started-images">
        <div className="screenshot">
          <img src={processImage1} alt="Process 1 Screenshot" />
          <p>Start offloading data collection and processing tasks immediately</p>
        </div>
        <div className="screenshot">
          <img src={processImage2} alt="Process 2 Screenshot" />
          <p>Eliminate AI training turnaround time and costs using our Automation</p>
        </div>
      </div>
    </section>
  );
}

export default GetStarted;
