import React from 'react';
import './UseCases.css';
import dataLabelingImage from '../assets/images/data-labeling.png';  // Example image path
import syntheticDataImage from '../assets/images/synthetic-data.png';  // Example image path
import fineTuningImage from '../assets/images/fine-tuning.png';  // Example image path
import rlhfImage from '../assets/images/rlhf.png';  // Example image path

const UseCases = () => {
  return (
    <section className="use-cases" id="use-cases">
      <h2>Use Cases</h2>
      <div className="use-cases-container">
        <div className="case-card">
          <img src={dataLabelingImage} alt="Data Labelling" className="case-card-image" />
          <h3>AI Assisted Data Labelling</h3>
          <p>Streamline your AI development process by outsourcing </p>
          <p>high-quality data labeling to enhance model accuracy </p>
          <p>Our platform supports labeling for various data types </p>
        </div>
        <div className="case-card">
          <img src={syntheticDataImage} alt="Synthetic Data" className="case-card-image" />
          <h3>Synthetic Data Genration & Anonymization</h3>
          <p>Accelerate your AI projects with synthetic data that simulates real-world.</p>
          <p>Generate large-scale, diverse datasets to overcome limitations in data.</p>
          <p>Anonymize Data cross parties to scale ai privacy and security.</p>
        </div>
        <div className="case-card">
          <img src={fineTuningImage} alt="Fine Tuning" className="case-card-image" />
          <h3>Automated MultiModal Fine Tuning</h3>
          <p>Tailor pre-trained AI models to your specific business needs.</p>
          <p>Adapt to specialized tasks, improving accuracy and performance.</p>
          <p>Automate Training process across multiple data types.</p>
        </div>
        <div className="case-card">
          <img src={rlhfImage} alt="RLHF" className="case-card-image" />
          <h3>RLHF & RLAIF</h3>
          <p>Incorporating human & AI feedback into the training loop.</p>
          <p>Enable AI models to better understand nuanced human preferences.</p>
          <p>Improving model performance and aligning it with real-world outcomes.</p>
        </div>
      </div>
    </section>
  );
}

export default UseCases;
