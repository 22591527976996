import React from 'react';
import './Header.css';
import logo from '../assets/images/logo.png';

const Header = () => {
  return (
    <header className="header">
      <div className="logo">
        <img src={logo} alt="TagflowAI Logo" />
        <strong>TagflowAI</strong>
      </div>
      <nav>
        <ul>
          <li><a href="#use-cases">Use Cases</a></li>
          <li><a href="#get-started">Get Started</a></li> {/* Added link to Get Started */}
          <li><a href="#blog">Blog</a></li> {/* Added link to Blog */}
          <li><a href="#about-us">About Us</a></li>
          <li>
            <a href="https://calendly.com/gabrielbaki/oow-meeting-clone" target="_blank" rel="noopener noreferrer">
                <button className="login-btn">Login</button>
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;