import React from 'react';
import './Blog.css';
import post1 from '../assets/images/post-1.png'; // Placeholder for image 1
import post2 from '../assets/images/post-2.png'; // Placeholder for image 2
import post3 from '../assets/images/post-3.png'; // Placeholder for image 3

const Blog = () => {
  return (
    <section className="blog" id="blog"> {/* Added ID */}
      <h2>How Design Partnership Impacts our Clients</h2>
      <div className="blog-posts">
        <div className="post">
          <img src={post1} alt="Legal Document Review" />
          <h3>Law Students Collaborate with AI for Document Review</h3>
          <p>September 30, 2024 - Case Study</p>
        </div>
        <div className="post">
          <img src={post2} alt="Game Asset Generation" />
          <h3>Empowering Creativity by Scaling Artistic Production</h3>
          <p>October 1, 2024 - Case Study</p>
        </div>
        <div className="post">
          <img src={post3} alt="Biochem Labelling" />
          <h3>Accelerate Drug Discovery with AI Biochemical Labeling</h3>
          <p>October 3, 2024 - Case Study</p>
        </div>
      </div>
    </section>
  );
}

export default Blog;
