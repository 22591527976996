import React from 'react';
import Header from './components/Header';
import MainSection from './components/MainSection';
import UseCases from './components/UseCases';
import GetStarted from './components/GetStarted';
import Blog from './components/Blog';
import AboutUs from './components/AboutUs';
import Footer from './components/Footer';

import './App.css'; // global styling

function App() {
  return (
    <div className="App">
      <Header />
      <MainSection />
      <UseCases />
      <GetStarted />
      <Blog />
      <AboutUs />
      <Footer />
    </div>
  );
}

export default App;