import React from 'react';
import './MainSection.css';

const MainSection = () => {
  return (
    <section className="main-section">
      <h1>Next Generation Automated <span className="highlight">AI Model Training</span> Platform</h1>
      <p>Utilize MultiModal Fine Tuning, AI Assisted Labelling, Synthetic Data Generation and Anonymization to accelerate your AI.</p>
      <a href="https://calendly.com/gabrielbaki/oow-meeting-clone" target="_blank" rel="noopener noreferrer">
        <button className="request-demo-btn">Request a Demo</button>
      </a>
      <div className="partners">
        {/*<img src="/assets/images/harvard-logo.png" alt="Harvard" />
        <img src="/assets/images/aws-logo.png" alt="AWS" />
        <img src="/assets/images/microsoft-logo.png" alt="Microsoft" />
  <img src="/assets/images/ninjavan-logo.png" alt="Ninjavan" />*/}
      </div>
    </section>
  );
}

export default MainSection;
